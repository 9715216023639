<template>
  <supplies></supplies>
</template>

<script>
import Supplies from '../admin/Supplies.vue'
export default {
  components: {
    Supplies
  }
}
</script>