<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="supplyModal"
      :title="$t('Actual sent quantity')"
      :buttons-hidden="true">
        <div class="bg-gray-50 vx-row mb-2" v-for="item in packagesSize" :key="item.index">
          <div class="vx-col w-1/2" v-if="item.package_size && item.package_size.short_code != 'OSZ'">
            <vs-input :disabled="true" v-validate="'required'" v-model="item.package_size.name" name="package" :placeholder="$t('Package')" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('package')">{{ errors.first('package') }}</span>
          </div>
          <div class="vx-col w-1/2" v-if="item.package_size && item.package_size.short_code != 'OSZ'">
            <vs-input-number :max="item.count" v-validate="'required|numeric'" :disabled="item.package_size.short_code === 'OSZ'" :label-placeholder="$t('Quantity')" name="quantity" v-model="item.count_delivered" :value="0" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="updateSupply" class="active-btn btn">{{ $t('Save') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['supplyModal', 'data'],
  data () {
    return {
      packagesSize: [],
      updatedItems: []
    }
  },
  watch: {
    'data' (val) {
      if (this.supplyModal) this.loadSupplyItems(val.id)
    },
    'packagesSize': {
      handler (val) {
        this.updatedItems = val.filter(item => typeof (item.count_delivered) === 'string')
      },
      deep: true
    }
  },
  methods: {
    updateSupply () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let counter = 0
          if (this.updatedItems.length === 0) {
            this.$emit('supplyModal', false)
          } else {
            this.updatedItems.forEach(element => {
              let packageObj = {}
              if (element.package_size && element.count > 0) {
                packageObj = {
                  count_delivered: Number(element.count_delivered),
                  id: element.id,
                  package_size: element.package_size.id
                }
              }
              sendRequest(false, false, this, `api/v1/supplies-items/${element.id}/`, 'patch', packageObj, true,
                () => {
                  counter = counter + 1
                  if (this.updatedItems.length === counter) {
                    this.updateStatus()
                    this.$emit('loadSupplies')
                    this.$emit('supplyModal', false)
                  }
                }
              )
            })
          }
        }
      })
    },
    updateStatus () {
      sendRequest(false, false, this, `api/v1/supplies/${this.data.id}/`, 'patch', {status: 'out_for_delivery'}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title: 'Success',
            text: 'Supplies items have been updated successfully!',
            position: 'top-center'
          })
        }
      )
    },
    closeModal () {
      this.data = ''
      this.$emit('supplyModal', false)
      this.$emit('loadSupplies')
    },
    loadSupplyItems (id) {
      sendRequest(false, false, this, `api/v1/supplies/${id}/`, 'get', null, true, 
        (response) => {
          this.packagesSize = response.data.supply_request_items.filter(item => item.count > 0)
        }
      )
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss"
</style>
